import type { DateTimeFormatOptions } from 'next-intl';
import { cva, cx } from 'class-variance-authority';
import dayjs from 'dayjs';
import { useFormatter, useNow, useTranslations } from 'next-intl';
import Image from 'next/image';

import { Avatar, Badge, cardStyles, LinkBox, LinkOverlay } from '@zealy/design-system';
import { CalendarEventLine, GiftLine, TimeFilled } from '@zealy/icons';

import { cn } from '#utils/utils';

import type { SprintCardProps } from './SprintCard.types';

const TEN_SECONDS = 10000;
const DATETIME_OPTIONS: DateTimeFormatOptions = {
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  hour12: true,
};

const styles = cva(['flex items-center gap-150 p-300 select-none'], {
  variants: {
    isClickable: {
      true: 'transition-transform hover:scale-[1.01] duration-200 ease-out',
    },
  },
});

export const SprintCard = ({
  className,
  title,
  communityName,
  communityAvatarImgSrc,
  startDate,
  endDate,
  rewardZone = 0,
  sprintImgSrc,
  onClick,
}: SprintCardProps) => {
  const startDateIsValid = !!startDate && dayjs(startDate).isValid();
  const endDateIsValid = !!endDate && dayjs(endDate).isValid();

  const format = useFormatter();
  const t = useTranslations('sprints');
  const mountDate = useNow();
  const updateInterval =
    startDateIsValid && dayjs(startDate).diff(mountDate, 'seconds') <= 180
      ? TEN_SECONDS
      : undefined;
  const now = useNow({
    updateInterval,
  });

  const timeBadgeLabel = startDateIsValid ? format.relativeTime(startDate, now) : undefined;
  const startDateLabel = startDateIsValid
    ? format.dateTime(startDate, DATETIME_OPTIONS)
    : undefined;
  const endDateLabel = endDateIsValid ? format.dateTime(endDate, DATETIME_OPTIONS) : undefined;
  const rewardsLabel = Math.max(rewardZone ?? 0, 0)
    ? t('rewards', { count: rewardZone })
    : undefined;
  const titleLabel = title ? title : t('placeholders.sprint-title', { communityName });
  const communityNameLabel = communityName ? communityName : t('placeholders.unknown-community');

  const handleKeyDown = (event: React.KeyboardEvent<HTMLAnchorElement>) => {
    if (event.key === 'Enter' && onClick) {
      onClick();
    }
  };

  return (
    <LinkBox
      className={cx(
        styles({ isClickable: !!onClick }),
        cardStyles({ variant: 'default', isClickable: !!onClick }),
        className,
      )}
    >
      <div className="flex flex-col flex-grow self-start gap-200 justify-between min-w-0">
        <span className="heading-3 truncate">{titleLabel}</span>
        <div className="flex flex-col gap-100 text-secondary body-md">
          <span className="flex gap-100 items-center">
            <Avatar
              className="flex-shrink-0 pointer-events-none"
              tabIndex={-1}
              size="xxs"
              name={communityName}
              src={communityAvatarImgSrc}
            />
            <span className="truncate">
              {t('by-community', { communityName: communityNameLabel })}
            </span>
          </span>
          <span className="flex gap-100 items-center">
            <CalendarEventLine className="flex-shrink-0" size={16} />
            <span className="truncate">
              {startDateIsValid && endDateIsValid ? (
                <>
                  <time dateTime={startDate.toISOString()}>{startDateLabel}</time>
                  {' – '}
                  <time dateTime={endDate.toISOString()}>{endDateLabel}</time>
                </>
              ) : (
                t('placeholders.unspecified-time')
              )}
            </span>
          </span>
          {rewardsLabel ? (
            <span className="flex gap-100 items-center">
              <GiftLine className="flex-shrink-0" size={16} />
              <span className="truncate">{rewardsLabel}</span>
            </span>
          ) : null}
        </div>
      </div>
      <div
        className={cn('shrink-0 w-[112px] h-[112px] relative', { ['self-start']: !sprintImgSrc })}
      >
        {sprintImgSrc ? (
          <Image
            className="w-full h-full object-cover rounded-xl"
            alt=""
            src={sprintImgSrc}
            width={112}
            height={112}
          />
        ) : null}
        {timeBadgeLabel ? (
          <Badge
            className={cn(
              'absolute top-0',
              sprintImgSrc ? '-right-200 -translate-y-1/2' : 'right-0',
            )}
            icon={<TimeFilled size={12} />}
            size="xs"
            variant="neutral"
            blurEffect
          >
            {timeBadgeLabel}
          </Badge>
        ) : null}
      </div>
      {onClick ? (
        <LinkOverlay
          As="div"
          role="button"
          aria-label={titleLabel}
          tabIndex={0}
          onClick={onClick}
          onKeyDown={handleKeyDown}
        />
      ) : null}
    </LinkBox>
  );
};

import { isHexColor } from '@zealy/utils';

import type { SprintHeaderProps } from '#components/SprintHeader';
import { SprintHeader } from '#components/SprintHeader';
import { cn } from '#utils/utils';

type SprintPreviewModalHeaderProps = Omit<SprintHeaderProps, 'style'> & {
  color?: string | null;
};

export const SprintPreviewModalHeader = ({
  color,
  className,
  ...restProps
}: SprintPreviewModalHeaderProps) => {
  const hasValidColor = !!color && isHexColor(color);

  return (
    <SprintHeader
      {...restProps}
      style={hasValidColor ? ({ '--sprint-color': color } as React.CSSProperties) : undefined}
      className={cn(
        'px-400 pt-400 pb-700',
        {
          ['bg-brand-secondary']: !hasValidColor,
          ['bg-[--sprint-color]']: hasValidColor,
        },
        className,
      )}
    />
  );
};

'use client';

import Image from 'next/image';

import {
  DefaultScreenSize,
  useMediaQuery,
} from '@zealy/design-system/dist/src/hooks/useMediaQuery';

export const SprintImage = ({ src }: { src: string }) => {
  console.log(DefaultScreenSize.sm);
  const isMobile = useMediaQuery(DefaultScreenSize.xs);
  if (isMobile) return null;
  return (
    <Image
      className="self-center object-cover rounded-xl aspect-square flex-shrink-0"
      alt=""
      src={src}
      width={164}
      height={164}
    />
  );
};

const { Node, mergeAttributes, nodePasteRule } = require('@tiptap/core');
const TWEET_REGEX_GLOBAL =
  /^((https?):\/\/)?(www.)?(twitter\.com|x\.com)(\/@?(\w){1,15})\/status\/[0-9]{19}/g;

// TODO: use twitter official embed instead
const getTwitterEmbedUrl = (url: string) => {
  // hack until we find a better solution, or until twitter.com is deprecated
  if (url.includes('x.com')) {
    url = url.replace('x.com', 'twitter.com');
  }
  return `https://twitframe.com/show?theme=dark&url=${encodeURIComponent(url)}`;
};

export const Tweet = Node.create({
  name: 'tweet',
  addOptions() {
    return {
      addPasteHandler: true,
      height: 350,
      HTMLAttributes: {},
      inline: false,
      width: 550,
    };
  },
  inline() {
    return this.options.inline;
  },
  group() {
    return this.options.inline ? 'inline' : 'block';
  },
  draggable: true,
  addAttributes() {
    return {
      src: {
        default: null,
      },
      width: {
        default: this.options.width,
      },
      height: {
        default: this.options.height,
      },
    };
  },
  parseHTML() {
    return [
      {
        tag: 'div[data-tweet-video] iframe',
      },
    ];
  },
  addPasteRules() {
    return [
      nodePasteRule({
        find: TWEET_REGEX_GLOBAL,
        type: this.type,
        getAttributes: (match: { input: string }) => {
          return { src: match.input };
        },
      }),
    ];
  },

  renderHTML({ HTMLAttributes }: { HTMLAttributes: Record<string, unknown> }) {
    const embedUrl = getTwitterEmbedUrl(HTMLAttributes.src as string);
    HTMLAttributes.src = embedUrl;
    return [
      'div',
      { 'data-tweet-video': '' },
      [
        'iframe',
        mergeAttributes(
          this.options.HTMLAttributes,
          {
            width: this.options.width,
            height: this.options.height,
          },
          HTMLAttributes,
        ),
      ],
    ];
  },
});

export default Tweet;

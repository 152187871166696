import * as React from 'react';
export function useMediaQuery(query) {
    const [value, setValue] = React.useState(false);
    React.useEffect(() => {
        function onChange(event) {
            setValue(event.matches);
        }
        const result = matchMedia(query);
        result.addEventListener('change', onChange);
        setValue(result.matches);
        return () => result.removeEventListener('change', onChange);
    }, [query]);
    return value;
}
export const DefaultScreenSize = {
    xs: '(max-width: 639px)',
    sm: '(max-width: 767px)',
    md: '(max-width: 1023px)',
    lg: '(max-width: 1279px)',
    xl: '(max-width: 1535px)',
    '2xl': '(min-width: 1536px)',
};

'use client';

import React, { useMemo } from 'react';
import { useTranslations } from 'next-intl';

import { useInfiniteCommunities } from '@zealy/queries';

import type { CommunityCardProps } from '#components';
import type { CategoryType } from '#constants';
import { Carousel, CommunityCard } from '#components';

const HOME_PAGE_CAROUSEL_LIMIT_SIZE = 6;

export const CategoryCarousel = ({
  web2,
  ...collection
}: {
  value: CategoryType;
  seo: string;
  inlineInDesktop: boolean;
  icon: React.ReactNode;
  trackClick?: boolean;
  web2?: boolean;
}) => {
  const t = useTranslations('explore');

  const { fetchNextPage, data } = useInfiniteCommunities(
    {
      category: collection.value === 'trend' ? 'all' : collection.value,
      endpoint: '/communities',
      web2,
    },
    HOME_PAGE_CAROUSEL_LIMIT_SIZE,
  );

  const communities = useMemo(
    () => (data?.pages ?? []).flatMap(page => page.results),
    [data?.pages],
  );

  if (!communities?.length) return null;

  const shouldShowRank = collection.value === 'trend';

  const totalCommunities = data?.pages?.[0]?.totalCommunities ?? 0;

  return (
    <Carousel
      showAllLabel={t('show-all')}
      showAllLink={`/explore/${collection.seo}`}
      title={t(`categories.${collection.value}.title`)}
      totalItems={totalCommunities}
      onFetchMore={fetchNextPage}
      key={collection.seo}
      className="py-400"
    >
      {communities?.map(
        (community: CommunityCardProps['community']) =>
          community && (
            <CommunityCard
              showRank={shouldShowRank}
              key={community.id}
              community={community}
              color="green"
              className="w-[458px] max-w-[85vw]"
              trackClick={'trackClick' in collection ? collection.trackClick : false}
              collection={collection.value}
            />
          ),
      )}
    </Carousel>
  );
};

import type { ComponentProps } from 'react';
import React from 'react';
import Image from 'next/image';
import Link from 'next/link';

import blue from './assets/blue.webp';
import cyan from './assets/cyan.webp';
import hotpink from './assets/hotpink.webp';
import pink from './assets/pink.webp';
import yellow from './assets/yellow.webp';

const images = {
  blue,
  cyan,
  hotpink,
  pink,
  yellow,
};

type CategoryCardProps = {
  color: keyof typeof images;
  title: string;
  href: string;
} & ComponentProps<typeof Link>;

export const CategoryCard = ({ color, title, href, ...props }: CategoryCardProps) => {
  return (
    <Link
      href={href}
      className={
        'inline-block relative w-[217px] rounded-component-md hover:blur-child z-[1] cursor-pointer transition-transform hover:scale-[1.02] duration-200 ease-out'
      }
      {...props}
    >
      <div className={'w-full h-[100px] grid content-center rounded-component-md'}>
        <h6 className="title-1 font-[700] mx-auto leading-none select-none text-white text-shadow shadow-[#00000075]">
          {title}
        </h6>
      </div>
      <Image
        src={images[color]}
        alt=""
        width="204"
        height="100"
        loading="lazy"
        decoding="async"
        className="select-none pointer-events-none absolute top-0 bottom-0 left-0 right-0 bg-cover w-full h-full rounded-component-md blurred transition-all duration-300 ease-in-out z-[-1]"
      />

      <Image
        src={images[color]}
        alt=""
        width="204"
        height="100"
        loading="lazy"
        decoding="async"
        className="select-none pointer-events-none absolute top-0 bottom-0 left-0 right-0 bg-cover w-full h-full rounded-component-md z-[-1] saturate-150"
      />
    </Link>
  );
};

export const CATEGORY_COLORS = ['blue', 'hotpink', 'yellow', 'cyan', 'pink'] as const;

'use client';

import { useState } from 'react';
import dayjs from 'dayjs';
import { useTranslations } from 'next-intl';

import { useUpcomingAndRecentSprints } from '@zealy/queries';
import { type ListSprintsOutput } from '@zealy/schemas';

import { Carousel, SprintCard } from '#components';

import { SprintPreviewModal } from './SprintPreviewModal';

type SprintData = ListSprintsOutput['data'][number];

export default function SprintsCarousel() {
  const [selectedSprint, setSelectedSprint] = useState<null | SprintData>(null);
  const t = useTranslations('sprints');
  const { data: sprints = [] } = useUpcomingAndRecentSprints();
  const handleOpenChange = (open: boolean) => {
    if (!open) {
      setSelectedSprint(null);
    }
  };

  if (sprints.length === 0) {
    return null;
  }

  return (
    <>
      <Carousel title={t('title')} className="py-400">
        {sprints.map(sprint => (
          <SprintCard
            key={sprint.id}
            title={sprint.name}
            startDate={dayjs(sprint.startingAt).toDate()}
            endDate={dayjs(sprint.endingAt).toDate()}
            rewardZone={sprint.rewardZone}
            communityName={sprint.communityName}
            communityAvatarImgSrc={sprint.communityImage}
            sprintImgSrc={sprint.image}
            onClick={() => {
              setSelectedSprint(sprint);
            }}
            className="w-[458px] max-w-[85vw] h-full"
          />
        ))}
      </Carousel>
      {selectedSprint ? (
        <SprintPreviewModal
          open
          className="sm:w-[708px] sm:h-[776px]"
          onOpenChange={handleOpenChange}
        >
          <SprintPreviewModal.Header
            sprintId={selectedSprint.id}
            sprintImgSrc={selectedSprint.image}
            communityName={selectedSprint.communityName}
            communityAvatarImgSrc={selectedSprint.communityImage}
            communitySubdomain={selectedSprint.communitySubdomain}
            title={selectedSprint.name}
            startDate={dayjs(selectedSprint.startingAt).toDate()}
            endDate={dayjs(selectedSprint.endingAt).toDate()}
            participants={selectedSprint.participants}
            color={selectedSprint.color}
          />
          <SprintPreviewModal.Content
            description={selectedSprint.description}
            rewards={selectedSprint.rewards}
          />
        </SprintPreviewModal>
      ) : null}
    </>
  );
}

import { uploadImage } from '@zealy/queries';
import { getSubdomain } from '@zealy/utils';

import { toast } from '#components/Toaster';
import { compressFile } from '#utils/compressFile';

const ALLOWED_IMAGE_TYPES = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];

const ALLOWED_IMAGE_SIZE = 10 * 1024 * 1024; // 10MB

export const handleFileUpload = (file: File, onSuccess: (src: string) => void) => {
  if (!ALLOWED_IMAGE_TYPES.includes(file.type)) {
    toast.error('Image must be a jpg or png');
    return true;
  }

  const filesize = (file.size / 1024 / 1024).toFixed(4); // MB

  if (Number(filesize) < ALLOWED_IMAGE_SIZE) {
    const _URL = window.URL || window.webkitURL;
    const img = new Image();
    img.src = _URL.createObjectURL(file);
    img.onload = async function () {
      const compressedFile = await compressFile(file);

      uploadImage(compressedFile, getSubdomain())
        .then(onSuccess)
        .catch(function (error: unknown) {
          if (error) {
            toast.error('There was a problem uploading your image, please try again.');
          }
        });
    };
  } else {
    toast.error('Image must smaller than 10MB');
  }
};

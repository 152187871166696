import React from 'react';
import { useTranslations } from 'next-intl';
import Image from 'next/image';

import { Link } from '@zealy/design-system';
import { ArrowRightLine } from '@zealy/icons';

const COMPANIES = [
  {
    name: 'Ubisoft',
    image: '/nstatic/about/companies/ubisoft.svg',
  },
  {
    name: 'Homa',
    image: '/nstatic/about/companies/homa.svg',
  },
  {
    name: 'LVMH',
    image: '/nstatic/about/companies/lvmh.svg',
  },
  {
    name: 'Michelin',
    image: '/nstatic/about/companies/michelin.svg',
  },
  {
    name: 'Fiat',
    image: '/nstatic/about/companies/fiat.svg',
  },
  {
    name: 'Nissan',
    image: '/nstatic/about/companies/nissan.svg',
  },
  {
    name: 'Renault',
    image: '/nstatic/about/companies/renault.svg',
  },
  {
    name: 'Alpine',
    image: '/nstatic/about/companies/alpine.svg',
  },
  {
    name: 'PMU',
    image: '/nstatic/about/companies/pmu.png',
  },
  {
    name: 'Polygon',
    image: '/nstatic/about/companies/polygon.svg',
  },
  {
    name: 'immutableX',
    image: '/nstatic/about/companies/immutableX.svg',
  },
  {
    name: 'dydx',
    image: '/nstatic/about/companies/dydx.svg',
  },
] as const;

const URL_CUSTOMER_STORIES = 'https://blog.zealy.io/case-studies-michelin/' as const;

export const Companies = ({
  hideCustomerStoriesLink = false,
}: {
  hideCustomerStoriesLink?: boolean;
}) => {
  const t = useTranslations('about.users');
  return (
    <div className="col-span-4 md:col-span-6 md:col-start-4 flex flex-col gap-200 text-center pb-800">
      <h4 className="section-heading text-brand-primary">{t('subtitle')}</h4>
      <h2 className="heading-1 text-primary whitespace-pre-line">{t('title')}</h2>
      <p className="text-secondary body-xl">{t('description')}</p>

      {!hideCustomerStoriesLink && (
        <Link href={URL_CUSTOMER_STORIES} rightIcon={<ArrowRightLine />} size="lg">
          {t('cta')}
        </Link>
      )}

      <div className="grid grid-cols-3 md:grid-cols-4 gap-600 md:gap-800 mt-400 items-center">
        {COMPANIES.map(({ name, image }) => (
          <Image
            key={name}
            src={image}
            alt={name}
            width={120}
            height={48}
            className="object-center object-contain icon-primary max-h-[48px]"
          />
        ))}
      </div>
    </div>
  );
};

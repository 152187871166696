import { EditorProps } from '@tiptap/pm/view';

import { handleFileUpload } from './handleImageUpload';

/**
 * Drop callback handling image uploads
 * https://www.codemzy.com/blog/tiptap-drag-drop-image
 */
export const handleDrop: EditorProps['handleDrop'] = (view, event, slice, moved) => {
  if (!moved && event.dataTransfer?.files?.[0]) {
    // the dropped file
    const file = event.dataTransfer.files[0];

    handleFileUpload(file, src => {
      // pre-load the image before responding so loading indicators can stay
      // and swaps out smoothly when image is ready
      const image = new Image();
      image.src = src;
      image.onload = function () {
        // place the now uploaded image in the editor where it was dropped
        const { schema } = view.state;
        const coordinates = view.posAtCoords({ left: event.clientX, top: event.clientY });
        const node = schema.nodes.image.create({ src }); // creates the image element
        const transaction = view.state.tr.insert(coordinates?.pos ?? 0, node); // places it in the correct position
        return view.dispatch(transaction);
      };
    });

    return true; // handled
  }
  return false; // not handled use default behaviour
};

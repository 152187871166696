'use client';

import React, { useRef } from 'react';

import { Button } from '@zealy/design-system';
import { Image2Line } from '@zealy/icons';

import { useTipTap } from './Editor.hooks';
import { handleFileUpload } from './handleImageUpload';

const { EditorContent } = require('@tiptap/react');

export const Editor = ({
  content,
  onChange,
}: {
  content: any;
  onChange: (content: any) => void;
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const editor = useTipTap({
    content,
    editable: true,
  });

  editor?.on('blur', ({ editor: e }: { editor: typeof editor }) => {
    onChange(e.getJSON());
  });

  editor?.on('focus', ({ editor: e }: { editor: typeof editor }) => {
    onChange(e.getJSON());
  });

  return (
    <div
      className="m-50 transition-all group rounded-component-sm ring-1 ring-offset-4 ring-offset-[var(--color-bg-tertiary)] ring-text-field hover:ring-text-field-hover focus-within:!ring-text-field-focus cursor-text"
      onClick={() => editor?.commands.focus()}
    >
      <EditorContent {...{ editor }} />

      <Button
        leftIcon={<Image2Line />}
        onlyIcon
        size="sm"
        variant="ghost"
        className="float-right opacity-0 group-hover:opacity-100 group-focus-within:opacity-100"
        mutedText
        onClick={() => inputRef.current?.click()}
        type="button"
      />

      <input
        className="hidden"
        id="fileinput"
        ref={inputRef}
        type="file"
        accept="image/jpeg,image/png,image/gif,image/webp"
        onChange={async e => {
          if (e.target.files?.[0]) {
            handleFileUpload(e.target.files?.[0], src => {
              editor?.chain().focus().setImage({ src }).run();
            });
          }
        }}
      />
    </div>
  );
};

'use client';

import { useMemo } from 'react';

import { useUserCommunities } from '@zealy/queries';

export const useMyCommunities = () => {
  const { data, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage } = useUserCommunities();
  const userCommunities = useMemo(
    () => data?.pages?.flatMap(page => page.results || []) || [],
    [data],
  );

  return { data: userCommunities, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage };
};

import { useTranslations } from 'next-intl';

import type { TipTapContent } from '@zealy/utils';

import { TipTapToContent } from '#components/Editor';

type SprintPreviewModalContentProps = {
  description?: string | null;
  rewards?: TipTapContent | null;
};

export const SprintPreviewModalContent = ({
  description,
  rewards,
}: SprintPreviewModalContentProps) => {
  const t = useTranslations('sprints');

  return (
    <div className="flex flex-col relative -top-300 py-250 px-400 gap-300 bg-tertiary rounded-sm flex-grow">
      {description ? <p className="body-paragraph-lg text-secondary">{description}</p> : null}
      {rewards ? (
        <div className="flex flex-col gap-100">
          <span className="body-paragraph-lg-bold text-primary">{t('rewards-header')}</span>
          <TipTapToContent content={rewards} />
        </div>
      ) : null}
    </div>
  );
};

import { EditorProps } from '@tiptap/pm/view';

import { handleFileUpload } from './handleImageUpload';

/**
 * Paste callback handling image uploads
 * https://www.codemzy.com/blog/tiptap-pasting-images
 */
export const handlePaste: EditorProps['handlePaste'] = (view, event) => {
  const items = Array.from(event.clipboardData?.items ?? []);
  const { schema } = view.state;

  items.forEach(item => {
    const image = item.getAsFile();

    if (image) {
      handleFileUpload(image, src => {
        const node = schema.nodes.image.create({
          src,
        });

        const transaction = view.state.tr.replaceSelectionWith(node);
        view.dispatch(transaction);
      });
    }
  });

  return false; // not handled use default behaviour
};

import type { ModalProps } from '#components/Modal';
import { Modal } from '#components/Modal';
import { cn } from '#utils/utils';

import { SprintPreviewModalContent } from './SprintPreviewModalContent';
import { SprintPreviewModalHeader } from './SprintPreviewModalHeader';

type SprintPreviewModalProps = Pick<ModalProps, 'className' | 'open' | 'onOpenChange'> & {
  children?: React.ReactNode;
};

export const SprintPreviewModal = ({
  className,
  open,
  onOpenChange,
  children,
}: SprintPreviewModalProps) => (
  <Modal className={cn('flex flex-col', className)} open={open} onOpenChange={onOpenChange}>
    {children}
  </Modal>
);

SprintPreviewModal.Header = SprintPreviewModalHeader;
SprintPreviewModal.Content = SprintPreviewModalContent;

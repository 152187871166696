import { cx } from 'class-variance-authority';

import { Button } from '@zealy/design-system';

import { BannerProps } from './Banner.types';

export const Banner = ({ description, title, actionLabel, className, ...rest }: BannerProps) => {
  return (
    <div
      role="banner"
      className={cx(
        'ring-2 rounded-component-md ring-inset ring-[color:#f0f1e73b]',
        'relative overflow-hidden',
        'bg-center bg-cover dark',
        className,
      )}
    >
      <div className="flex flex-col pt-400 pb-400 pl-300 pr-[80px] md:px-500 md:py-700 items-start gap-100 md:max-w-[374px]">
        {title && <h1 className="heading-3 text-primary">{title}</h1>}
        {description && <p className="text-primary body-xl mb-300">{description}</p>}
        {actionLabel && (
          <Button variant="muted" size="lg" {...rest}>
            {actionLabel}
          </Button>
        )}
      </div>
    </div>
  );
};

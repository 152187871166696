'use client';

import './editor.css';

import { cn } from '#utils/utils';

import Tweet from './Extensions/Twitter';
import Youtube from './Extensions/Youtube';
import { handleDrop } from './handleDrop';
import { handlePaste } from './handlePaste';

const Image = require('@tiptap/extension-image').default;
const StarterKit = require('@tiptap/starter-kit').default;
const Placeholder = require('@tiptap/extension-placeholder').default;
const { useEditor } = require('@tiptap/react');
const Link = require('@tiptap/extension-link').default;
const Underline = require('@tiptap/extension-underline').default;
const TaskItem = require('@tiptap/extension-task-item').default;
const TaskList = require('@tiptap/extension-task-list').default;
const Typography = require('@tiptap/extension-typography');
const css = require('highlight.js/lib/languages/css');
const js = require('highlight.js/lib/languages/javascript');
const ts = require('highlight.js/lib/languages/typescript');
const html = require('highlight.js/lib/languages/xml');
const CodeBlockLowlight = require('@tiptap/extension-code-block-lowlight').default;
const { lowlight } = require('lowlight/lib/core');

lowlight.registerLanguage('html', html);
lowlight.registerLanguage('css', css);
lowlight.registerLanguage('js', js);
lowlight.registerLanguage('ts', ts);

// no types for options in TipTap
export const useTipTap = ({ placeholder = 'Add description...', extensions, ...options }: any) => {
  const editor = useEditor(
    {
      editorProps: {
        attributes: {
          class: cn(
            'text-primary prose focus:outline-none',
            'prose-h1:headings-headline-h3 prose-h2:headings-title-t1 prose-h3:interface-sectionHeading',
            'selection:bg-brand-primary w-full max-w-full underline-offset-4',
            '[&_img]:max-w-full [&_img]:h-auto body-paragraph-lg [&_img]:rounded-component-xxs',
            '[&_li>p]:body-component-lg [&_li>p]:my-0',
          ),
          translate: options.editable ? undefined : 'yes',
        },
        handleDrop,
        handlePaste,
      },
      ...options,
      extensions: [
        StarterKit.configure({
          codeBlock: false,
          image: false,
        }),
        CodeBlockLowlight.configure({
          lowlight,
        }),
        Underline.configure({
          HTMLAttributes: {
            class: 'underline',
          },
        }),
        Link,
        Youtube.configure({
          height: 250,
          modestBranding: true,
          HTMLAttributes: {
            class: 'w-full outline-none block rounded-component-xs aspect-video',
          },
        }),
        Image,
        Placeholder.configure({
          emptyEditorClass:
            'cursor-text text-field-text-md before:text-field-text-md before:content-[attr(data-placeholder)] before:absolute before:top-0 before:left-0 before:text-tertiary before:opacity-50 before-pointer-events-none',
          placeholder,
        }),
        TaskList,
        TaskItem.configure({
          nested: true,
        }),
        Tweet,
        Typography,
        ...(extensions || []),
      ],
    },
    options.editable ? undefined : [options.content],
  );

  return editor;
};

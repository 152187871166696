import React from 'react';
import { useTranslations } from 'next-intl';
import NextLink from 'next/link';

import { Link } from '@zealy/design-system';
import { DiscordBrand, TelegramBrand, TwitterFilled } from '@zealy/icons';

import { COOKIE_POLICY_URL, PRIVACY_URL, TERMS_AND_CONDITIONS_URL } from '#constants';

import type { MenuProps } from './Footer.types';
import { LogoFull } from '../Logo';
import { DISCORD_URL, FOOTER_LINKS, TELEGRAM_URL, TWITTER_URL } from './Footer.const';

const Menu = ({ items, title }: MenuProps) => {
  const t = useTranslations('footer');
  return (
    <div className="flex flex-1 flex-col gap-300 w-full lg:w-[204px]">
      <h4 className="section-heading">{t(title)}</h4>
      <div className="flex flex-col items-start gap-200">
        {items.map((link, key) => (
          <Link prefetch={false} key={key.toString()} href={link.path} as={NextLink}>
            {t(link.label)}
          </Link>
        ))}
      </div>
    </div>
  );
};

export const Footer = () => {
  const t = useTranslations('footer');
  return (
    <footer className="col-span-4 md:col-span-12 pt-800 my-400">
      <div className="flex flex-col gap-200 justify-between md:flex-row">
        <div className="flex flex-col gap-300">
          <div className="flex flex-col max-w-[485px] gap-250">
            <LogoFull />
            <label className="text-tertiary body-lg">{t('information')}</label>
          </div>
        </div>
        <div className="pt-700 md:pt-0 grid grid-cols-2 md:flex justify-items-stretch md:flex-row gap-700 md:gap-300">
          <Menu {...FOOTER_LINKS.learn} />
          <Menu {...FOOTER_LINKS['get-started']} />
          <Menu {...FOOTER_LINKS.resources} />
        </div>
      </div>
      <div className="flex w-full py-200 gap-300 justify-between border-t-separators items-start mt-1200">
        <div className="flex flex-wrap w-full gap-400 self-start">
          <Link className="pointer-events-none" as="span">
            {t('copyright', { currentDate: new Date() })}
          </Link>
          <Link isExternal href={PRIVACY_URL}>
            {t('privacy-policy')}
          </Link>
          <Link isExternal href={COOKIE_POLICY_URL}>
            {t('cookie-policy')}
          </Link>
          <Link isExternal href={TERMS_AND_CONDITIONS_URL}>
            {t('terms-of-use')}
          </Link>
        </div>
        <div className="flex gap-250">
          <Link isExternal href={TWITTER_URL} leftIcon={<TwitterFilled />} />
          <Link isExternal href={DISCORD_URL} leftIcon={<DiscordBrand />} />
          <Link isExternal href={TELEGRAM_URL} leftIcon={<TelegramBrand />} />
        </div>
      </div>
    </footer>
  );
};

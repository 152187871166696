'use client';

import React from 'react';
import { useTranslations } from 'next-intl';

import type { CommunityCardProps } from '#components';
import { Carousel, CommunityCard } from '#components';
import { useMyCommunities } from '#hooks/useMyCommunities';

export const MyCommunitiesCarousel = () => {
  const tExplore = useTranslations('explore');
  const tMyCommunities = useTranslations('my-communities');

  const { data: userCommunities, isFetching, fetchNextPage, hasNextPage } = useMyCommunities();

  if (isFetching || userCommunities.length === 0) return null;

  return (
    <Carousel
      showAllLabel={tExplore('show-all')}
      showAllLink={'/my-communities'}
      title={tMyCommunities('title')}
      totalItems={userCommunities.length}
      key="my-communities"
      onFetchMore={hasNextPage ? fetchNextPage : undefined}
      className="py-400"
    >
      {userCommunities.map(
        (community: CommunityCardProps['community']) =>
          community && (
            <CommunityCard
              showRank={false}
              key={`my-communities:${community.id}`}
              community={community}
              color="green"
              className="w-[458px] max-w-[85vw]"
            />
          ),
      )}
    </Carousel>
  );
};

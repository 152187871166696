'use client';

import React from 'react';

import { useTipTap } from './Editor.hooks';

const { EditorContent } = require('@tiptap/react');

export const TipTapToContent = ({ content, ...options }: { content: any; editorProps?: any }) => {
  const editor = useTipTap({ content, editable: false, ...options });

  return <EditorContent {...{ editor }} />;
};

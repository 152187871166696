import dayjs from 'dayjs';

export enum SprintStatus {
  Completed = 'completed',
  Ongoing = 'ongoing',
  Planned = 'planned',
}

export const getSprintStatus = ({
  endingAt,
  startingAt,
}: {
  startingAt: string | Date;
  endingAt: string | Date;
}): SprintStatus => {
  if (dayjs().isAfter(endingAt)) {
    return SprintStatus.Completed;
  }

  if (dayjs().isAfter(startingAt) && dayjs().isBefore(endingAt)) {
    return SprintStatus.Ongoing;
  }

  return SprintStatus.Planned;
};
